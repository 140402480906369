<template>
  <div>
    <div v-if="tableDataFiltered.length">
      <div v-if="category" class="row mb-md-2 text-center">
        <div class="col-sm-12 col-md-auto">
          <div id="tickets-table_length" class="dataTables_length">
            <router-link
              v-if="userPermissionsIDs.includes(8)"
              :to="{
                name: 'Create subcategory',
                query: { category: category },
              }"
              class="btn btn-primary"
              >{{ $t("subcategory.add") }}</router-link
            >
          </div>
        </div>
        <!-- Search -->
        <div class="col-sm-12 col-md-auto ml-auto mt-2 mt-md-0">
          <div
            id="tickets-table_filter"
            class="dataTables_filter text-md-right"
          >
            <!-- <label class="d-inline-flex align-items-center mr-2">
              <span class="text-nowrap">{{ $t("quantity") }}:</span>
              <b-form-select
                v-model="perPage"
                size="sm"
                :options="pageOptions"
                class="form-control form-control-sm ml-2"
              ></b-form-select>
            </label> -->
            <label class="d-inline-flex align-items-center">
              {{ $t("search") }}:
              <b-form-input
                v-model="filter"
                type="search"
                placeholder="..."
                class="form-control form-control-sm ml-2"
              ></b-form-input>
            </label>
          </div>
        </div>
        <!-- End search -->
      </div>

      <div v-if="loading" class="text-center">
        <b-spinner variant="primary"></b-spinner>
      </div>
      <div v-else class="table-responsive mb-0">
        <b-table
          :items="tableDataFiltered"
          :fields="tableSubFields"
          responsive="sm"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
          :filter="filter"
          :filter-included-fields="filterOn"
          :thead-class="{ 'd-none': !category }"
          striped
        >
          <template #head(photo)>
            {{ $t("table.photo") }}
          </template>

          <template #head(name)>
            {{ $t("table.name") }}
          </template>

          <template #head(description)>
            {{ $t("table.description") }}
          </template>

          <!-- photo -->
          <template #cell(photo)="data">
            <img
              :src="data.item.photo || require('@/assets/images/nophoto.png')"
              width="48"
              height="auto"
            />
          </template>

          <!-- action -->
          <template #cell(action)="data">
            <div class="button-list text-nowrap">
              <router-link
                v-if="userPermissionsIDs.includes(7)"
                :to="{ path: '/subcategories/view/' + data.item.id }"
                class="btn btn-primary btn-xs"
                :title="$t('btn.view')"
              >
                <i class="mdi mdi-eye"></i>
              </router-link>
              <router-link
                v-if="userPermissionsIDs.includes(9)"
                :to="{ path: '/subcategories/edit/' + data.item.id }"
                class="btn btn-success btn-xs"
                :title="$t('btn.edit')"
              >
                <i class="mdi mdi-pencil"></i>
              </router-link>
              <b-button
                v-if="userPermissionsIDs.includes(10)"
                variant="danger"
                size="xs"
                @click="confirmDeleteSubcategory(data.item)"
                :title="$t('btn.delete')"
              >
                <i class="mdi mdi-close"></i>
              </b-button>
            </div>
          </template>
        </b-table>
      </div>

      <!-- <div v-if="category" class="row mt-2">
        <div class="col-6">
          <div class="my-1">{{ displayedRows }} / {{ totalRows }}</div>
        </div>
        <div class="col-6">
          <div class="dataTables_paginate paging_simple_numbers float-right">
            <ul class="pagination pagination-rounded mb-0">
              <b-pagination
                v-model="currentPage"
                :total-rows="totalRows"
                :per-page="perPage"
              ></b-pagination>
            </ul>
          </div>
        </div>
      </div> -->
    </div>
    <template v-else>
      <div v-if="category" class="text-center">
        <div class="mb-3">{{ $t("subcategory.no-subcategories") }}</div>
        <router-link
          :to="{ name: 'Create subcategory', query: { category: category } }"
          class="btn btn-primary"
          >{{ $t("subcategory.add") }}</router-link
        >
      </div>
    </template>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import axios from "axios";

export default {
  props: {
    subcategories: {
      type: Array,
      default: () => [],
    },
    category: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      loading: true,

      tableData: [],
      totalRows: 30,
      currentPage: 1,
      pageOptions: [10, 25, 50, 100],
      perPage: 100,
      filter: null,
      filterOn: [],
      sortBy: "id",
      sortDesc: false,

      tableSubFields: [
        {
          key: "id",
          sortable: true,
          class: "cell-id",
        },
        {
          key: "photo",
          class: "img-subcategory",
        },
        {
          key: "name",
          sortable: true,
          class: "cell-name",
        },
        {
          key: "description",
        },
        {
          key: "action",
          label: "",
          class: "cell-action",
        },
      ],
    };
  },
  created() {
    this.loadData();
  },
  computed: {
    ...mapGetters(["userPermissionsIDs"]),
    displayedRows() {
      const items = this.currentPage * this.perPage;
      return items < this.totalRows ? items : this.totalRows;
    },
    tableDataFiltered() {
      if (this.category) {
        return this.tableData.filter(
          (i) => String(i.category_id) === this.category
        );
      }

      return this.tableData;
    },
  },
  methods: {
    async loadData() {
      try {
        this.loadTableData();
      } catch (error) {
        console.log("loadData Error: ", error);
      }
    },
    async loadTableData(silent = false) {
      if (!silent) this.loading = true;

      // if data already in props - show in table
      if (this.subcategories.length) {
        this.tableData = this.subcategories;
        this.loading = false;
        return;
      }

      // req data with category id
      try {
        const response = await axios.get(
          this.$urls.URL_SUBCATEGORIES +
            `?category=${this.category}&page=${this.currentPage}&per_page=${this.perPage}`
        );
        // console.log("response: ", response);
        this.tableData = response.data.data;
        this.totalRows = response.data.total;
      } catch (error) {
        console.log("loadTableData, error: ", error);
      } finally {
        this.loading = false;
      }
    },
    confirmDeleteSubcategory(item) {
      this.$bvModal
        .msgBoxConfirm(
          `${this.$t("modal.want-delete-subcategory")}: ${item.name}?`,
          {
            title: this.$t("modal.confirm-delete"),
            size: "md",
            buttonSize: "md",
            okVariant: "danger",
            okTitle: this.$t("btn.yes"),
            cancelTitle: this.$t("btn.no"),
            footerClass: "p-2",
            hideHeaderClose: false,
            centered: true,
          }
        )
        .then((sure) => {
          if (sure) {
            this.deleteSubcategory(item.id);
          }
        })
        .catch((err) => {
          // An error occurred
        });
    },
    deleteSubcategory(id) {
      const formData = new FormData();
      formData.append("_method", "DELETE");

      axios
        .post(this.$urls.URL_SUBCATEGORIES + "/" + id, formData)
        .then(() => {
          if (this.category) {
            // in subcategory own page - list of subcategories
            this.loadTableData();
          } else {
            // in category page - in sub table
            this.$emit("updateData");
            this.tableData = this.tableData.filter((i) => i.id !== id);
          }
        })
        .catch(function(error) {
          console.log(error.response);
        });
    },
  },
  watch: {
    currentPage() {
      this.loadTableData();
    },
    perPage() {
      this.loadTableData();
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .img-subcategory {
  width: 48px;
  padding-left: 0;
  padding-right: 0;

  img {
    border-radius: 3px;
    width: 100%;
    height: auto;
  }
}
</style>
